<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'

export default {
	name: 'App',
	components: {
		// HelloWorld
	}
}
</script>

<style>
#app {
	font-family: "Cera GR", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* text-align: center; */
	@apply text-gray-500;
	/* margin-top: 60px; */
}
</style>
